import React from 'react'
import { Article, useArticleMetadata, KnowledgeBaseSearch } from '@thesisedu/gatsby-knowledge-base'
import { Link } from 'gatsby'
import { Block, Body } from '@thesisedu/web/dist'
import styled from 'styled-components'
import { Container, LargeH1, Subtitle } from '../components/styled'
import { SEO } from '../components/SEO'
import { MDPageContent } from '../components/MDPageContent'
import { SupportWidget } from '../components/support/SupportWidget'

export default function ArticleTemplate(props: any) {
  const { title, description } = useArticleMetadata(props)
  return (
    <Container>
      <SEO title={title} description={description} article />
      <div>
        <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
          <Block marginBottom={'@size-xs'}>
            <Subtitle>
              <Link to={'/learn/articles/'}>Knowledge Base</Link> &nbsp;/
            </Subtitle>
          </Block>
          <LargeH1 isBlock={false}>{title}</LargeH1>
        </Block>
        <SupportContent>
          <Article {...props} />
          <Block marginTop={'@size-section-sm'}>
            <Body isBlock style={{ textAlign: 'right' }}>
              <a href={'mailto:support@prodigiesacademy.com'}>
                Contact support about this article.
              </a>
            </Body>
            <KnowledgeBaseSearch />
          </Block>
        </SupportContent>
        <Block marginTop={'@size-section-md'}>
          <SupportWidget />
        </Block>
      </div>
    </Container>
  )
}

export const SupportContent = styled(MDPageContent)`
  max-width: 800px;
`
